<template>
    <v-container>
        <v-row>
            <v-col align="center">
                <clocks>
                </clocks>
            </v-col></v-row>
    </v-container>
</template>

<script>
import Clocks from '../components/Clocks.vue';

export default {
    components: {
        Clocks
    },

    data: () => ({
    }),

    mounted: function () {
    },

    methods: {
    },
};
</script>